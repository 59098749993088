import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import Context from "../../context/"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/underlining"
import { lightTheme, darkTheme } from "../../styles/theme"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'






export const StyledFullScreenWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
`

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: transparent; 
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 80%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0rem;
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: ${({ theme }) => theme.colors.accent};
    }
    }
    .subtitle {
      font-size: 1.25rem;
      font-weight: 100;
      margin-top: 0rem;
      color: ${({ theme }) => theme.colors.highlight};

    }
    .description {
      font-size: 1.125rem;
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.colors.highlight};
    }
  }
`

const AnimatedUnderlining = motion.custom(Underlining)

const Hero = ({ content }) => {

  const { frontmatter, body } = content[0].node
  const { isIntroDone, darkMode } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  // const eControls = useAnimation()
  // const sControls = useAnimation()
  const uControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
        // Animate underlining to hover state
        await uControls.start({
          boxShadow: `inset 0 -2rem 0 ${
            darkMode ? darkTheme.colors.secondary : lightTheme.colors.secondary
          }`,
          transition: { delay: 0.4, ease: "circOut" },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, gControls, uControls])

  return (
    <StyledSection id="hero">
       <StyledContentWrapper>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={gControls}
          data-testid="animated-heading"
        >
          
          <h5 className="title">  
            {frontmatter.title}
          </h5>

        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
